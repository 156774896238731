import { reactive, ref } from "vue";
import { LeaveHoliday } from "@/types/leave-configuration/leaveHolidays";
import { LeaveRequest } from "@/types/book-leaves/leaveRequests";
import { RequestStatus } from "@/helpers/leaveRequestFunctions";

import MaternityLeaveIcon from "@/assets/svg-components/maternity-leave-icon.vue";
import SickLeaveIcon from "@/assets/svg-components/sick-leave-icon.vue";
import BereaveMentLeaveIcon from "@/assets/svg-components/bereavement-leave-icon.vue";
export const showFilters = ref(false);
export const ListLeavesHolidays = ref<LeaveHoliday[]>([]);
export const isHolidaysVisible = ref(false);
export const searchedQuery = ref("");
export const messageBlock = reactive({
  open: false,
  severity: "",
  message: "",
});

export const showOnboarding = ref(true);
export const setLocal = () => {
  showOnboarding.value = false;
};
export const getLeaveTypeIcon = (leaveTypeTitle: string) => {
  switch (leaveTypeTitle) {
    case "Sick Leave":
      return SickLeaveIcon;
    case "Maternity Leave":
      return MaternityLeaveIcon;
    case "Bereavement Leave":
      return BereaveMentLeaveIcon;
    default:
      return null;
  }
};
export const leave: LeaveRequest = reactive<LeaveRequest>({
  id: "",
  created_by: "",
  is_hr_approved: false,
  leave_type_id: "",
  leave_type: "",
  selectedDate: {
    startDate: "",
    endDate: "",
  },
  description: "",
  document: "",
  file: "",
  dates: [],
  days: "",
  duration: 0,
  carry_over_used: 0,
  first_last_days_start: "",
  first_last_days_end: "",
  first_last_days: [],
  days_off: 0,
  status: "",
  comments: "",
  employee_detail: "",
  manager_approved: "",
  manager_id: "",
  is_archived: false,
  can_negate: false,
  touch_point_comments: [],
});

export const toggleFilters = () => {
  showFilters.value = !showFilters.value;
};

export const getSpanTitle = ([firstDay]: [Date, Date]):
  | "Upcoming timeoff"
  | "History" => {
  const currentDate = new Date();
  const startDate = new Date(firstDay);

  return startDate > currentDate ? "Upcoming timeoff" : "History";
};

export function searchTableData(searchString: string) {
  searchedQuery.value = searchString;
}

export const hasTouchPointComments = (item: LeaveRequest) => {
  return item["touch_point_comments"]?.[0]?.message.length > 0;
};

export const statusClass = (item: LeaveRequest) => {
  const { status, manager_approved } = item;

  if (status === RequestStatus.APPROVED) {
    return "text-[#10899e] bg-[#D3F5F7]";
  } else if (
    status === RequestStatus.PENDING &&
    manager_approved === RequestStatus.PENDING
  ) {
    return "text-[#b48429] bg-[#fcefd6]";
  } else if (status === RequestStatus.DENIED) {
    return "text-[#b42f2f] bg-[#f7c5c5]";
  } else if (
    manager_approved === RequestStatus.APPROVED &&
    status !== RequestStatus.DENIED
  ) {
    return "text-[#138fa5] bg-[#D3F5F7]";
  } else if (
    manager_approved === RequestStatus.DENIED &&
    status !== RequestStatus.APPROVED
  ) {
    return "text-[#ab2b2b] bg-[#f7c5c5]";
  } else if (manager_approved === RequestStatus.PENDING) {
    return "text-[#0e8ba1] bg-[#d1f6f8]";
  } else {
    return "";
  }
};

export const getStatusText = (item: LeaveRequest) => {
  const { status, manager_approved } = item;

  if (status === RequestStatus.APPROVED) {
    return "Approved by HR";
  } else if (status === RequestStatus.DENIED) {
    return "Disapproved by HR";
  } else if (
    manager_approved === RequestStatus.APPROVED &&
    status !== RequestStatus.DENIED
  ) {
    return "Approved by manager";
  } else if (
    manager_approved === RequestStatus.DENIED &&
    status !== RequestStatus.APPROVED
  ) {
    return "Disapproved by manager";
  } else if (
    status === RequestStatus.PENDING &&
    manager_approved === RequestStatus.PENDING
  ) {
    return "Pending approval";
  } else {
    return "";
  }
};

export const isLastItem = (slotProps: {
  displayedItems: number;
  index: number;
}) => {
  const { displayedItems, index } = slotProps;
  return displayedItems > 3
    ? [displayedItems - 1, displayedItems - 2, displayedItems - 3].includes(
        index
      )
    : [displayedItems - 1, displayedItems - 2].includes(index);
};

export const showActionButtons = (item: LeaveRequest) => {
  const { status, manager_approved } = item;
  return (
    status !== RequestStatus.APPROVED &&
    status !== RequestStatus.DENIED &&
    manager_approved !== RequestStatus.APPROVED &&
    manager_approved !== RequestStatus.DENIED
  );
};

export const formatDate = (date: Date, format: number) => {
  return date.toISOString().slice(0, format);
};

export const requestDays = [
  {
    id: 1,
    name: "Full Day",
  },
  {
    id: 1 / 8,
    name: "1 hour",
  },
  {
    id: 1 / 4,
    name: "2 hours",
  },
  {
    id: 3 / 8,
    name: "3 hours",
  },
  {
    id: 1 / 2,
    name: "4 hours",
  },
  {
    id: 5 / 8,
    name: "5 hours",
  },
  {
    id: 3 / 4,
    name: "6 hours",
  },
  {
    id: 7 / 8,
    name: "7 hours",
  },
];
