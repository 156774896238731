<template>
  <div class="settings z-50 BG0" :class="{
    'settings-open': store.showAll,
    'settings-closed': !store.showAll,
  }">
    <div class="flex items-center cursor-pointer relative">
      <a @click="routeUrl()" class="flex items-center gap-5 px-1">
        <div>
          <AmalitechLogo />
        </div>
        <p class="
            text-[27px]
            N700
            font-bold
            sm:hidden
            md:hidden
            lg:block
            xl:block
            mr-24
            hide_view
          " v-if="store.showAll">
          AmaliTech
        </p>
      </a>
      <span class="
          absolute
          h-6
          w-6
          rounded-full
          flex
          items-center
          py-0
          px-2
          shadow-md
          BG0
          top-8
          -right-7
          hide_view
        " @click="store.showAll = !store.showAll">
        <ArrowLeftIcon v-if="store.showAll" />
        <ArrowRightIcon v-if="!store.showAll" />
      </span>
    </div>
    <HorizontalLine class="mt-4" />
    <div>
      <NavigationComponent data-cy="dashboardItem" :item="{
        label: 'Home',
        route:
          $route.path === '/home'
            ? '/home'
            : $route.path === '/overview'
              ? '/overview'
              : $route.path === '/leave-assistance'
                ? '/leave-assistance'
                : '/',
      }" @mouseover="toggleOnDashboard(true)" @mouseout="toggleOnDashboard(false)">
        <DashboardIcon :color="onDashboard ||
            $route.path === '/home' ||
            $route.path === '/' ||
            $route.path === '/overview' ||
            $route.path === '/leave-assistance'
            ? '#DD5928'
            : '#8F95B2'
          " />
      </NavigationComponent>

      <NavigationComponent data-cy="managerDashboardItem" v-if="leavesPermissions?.view_manager_dashboard" :item="{
        label: 'Manager Dashboard',
        route:
          $route.path === '/leave-manager-leave-planner'
            ? '/leave-manager-leave-planner'
            : '/leave-manager-approval',
      }" @mouseover="toggleOnManagerdashboard(true)" @mouseout="toggleOnManagerdashboard(false)">
        <ManagerIcon :color="onManagerDashboard ||
            $route.path === '/leave-manager-approval' ||
            $route.path === '/leave-manager-leave-planner'
            ? '#DD5928'
            : '#8F95B2'
          " />
      </NavigationComponent>
      <NavigationComponent data-cy="hrDashboardItem" v-if="leavesPermissions?.view_hr_dashboard" :item="{
        label: 'HR Dashboard',
        route:
          $route.path === '/leave-hr-employee-information'
            ? '/leave-hr-employee-information'
            : '/leave-hr-approval',
      }" @mouseover="toggleOnHRdashboard(true)" @mouseout="toggleOnHRdashboard(false)">
        <HRIcon :color="onHRDashboard ||
            $route.path === '/leave-hr-approval' ||
            $route.path === '/leave-hr-employee-information'
            ? '#DD5928'
            : '#8F95B2'
          " />
      </NavigationComponent>

      <HorizontalLine v-if="
        leavesPermissions?.view_leave_type ||
        leavesPermissions?.view_leave_policy ||
        leavesPermissions?.view_leave_holidays ||
        leavesPermissions?.view_leave_tasks ||
        approversPermissions?.view_leave_approvers ||
        leavesPermissions?.view_leave_access_level
      " />
      <NavigationComponent data-cy="settingsItem" v-if="
        leavesPermissions?.view_leave_type ||
        leavesPermissions?.view_leave_policy ||
        leavesPermissions?.view_leave_holidays ||
        leavesPermissions?.view_leave_tasks ||
        approversPermissions?.view_leave_approvers ||
        leavesPermissions?.view_leave_access_level
      " :item="{
          label: 'Settings',
          route:
            $route.path === '/holidays-view'
              ? '/holidays-view'
              : $route.path === '/holidays-import-view'
                ? '/holidays-import-view'
                : $route.path === '/leave-policy'
                  ? '/leave-policy'
                  : $route.path === '/tasks-view'
                    ? '/tasks-view'
                    : $route.path === '/access-level-view'
                      ? '/access-level-view'
                      : $route.path === '/leave-approvers'
                        ? '/leave-approvers'
                        : '/leave-type',
        }" @mouseover="toggleOnSettings(true)" @mouseout="toggleOnSettings(false)">
        <SettingsIcon :color="onSettings ||
            $route.path === '/leave-type' ||
            $route.path === '/leave-policy' ||
            $route.path === '/holidays-view' ||
            $route.path === '/holidays-import-view' ||
            $route.path === '/tasks-view' ||
            $route.path === '/leave-approvers' ||
            $route.path === '/access-level-view'
            ? '#DD5928'
            : '#8F95B2'
          " />
      </NavigationComponent>
    </div>
  </div>
</template>
<script setup lang="ts">
import { ref } from "vue";
import { useStore } from "@/store";
import { useAccessStore } from "@/store/storeLeavesPermissions";
import ManagerIcon from "@/assets/manager-icon.vue";
import HRIcon from "@/assets/hr-icon.vue";
import DashboardIcon from "@/assets/dashboard-icon.vue";
import SettingsIcon from "@/assets/settings-icon.vue";
import NavigationComponent from "./NavigationComponent.vue";
import HorizontalLine from "@/common/ui-kit/HorizontalLine.vue";
import ArrowLeftIcon from "@/assets/arrow-left-icon.vue";
import ArrowRightIcon from "@/assets/arrow-right-icon.vue";
import { getToken } from "@/services/auth";
import config from "@/config/config";
import AmalitechLogo from "@/assets/amalitech-logo.vue";
// user permissions
const emits = defineEmits(["appSelected"]);
const Access = useAccessStore();
const leavesPermissions = ref(Access.permissions?.settings?.leave_management);
const approversPermissions = ref(Access.permissions?.settings?.approvers);

Access.$subscribe((_, state) => {
  leavesPermissions.value = state.permissions?.settings?.leave_management;
  approversPermissions.value = state.permissions?.settings?.approvers;
});

const store = useStore();
const onDashboard = ref(false);
const onManagerDashboard = ref(false);
const onHRDashboard = ref(false);
const onSettings = ref(false);

const toggleOnDashboard = (status: boolean) => {
  onDashboard.value = status;
};
const toggleOnManagerdashboard = (status: boolean) => {
  onManagerDashboard.value = status;
};
const toggleOnHRdashboard = (status: boolean) => {
  onHRDashboard.value = status;
};
const toggleOnSettings = (status: boolean) => {
  onSettings.value = status;
};

const loginHint: string = store.usersHint;
const routeUrl = () => {
  const token: string | null = getToken();
  if (token) {
    window.location.href = `${config.mainPageLink}?app-token=${token}&login-hint=${loginHint}`;
  }
};
</script>
<style scoped>
.settings {
  height: 100vh;
  font-weight: 400;
  font-size: 1rem;
  line-height: 24px;
  padding: 15px 18px;
  color: #474d66;
  border: 1px solid #e6e8f0;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.settings::-webkit-scrollbar {
  display: none;
}

.settings-open {
  width: 283px;
}

.settings-closed {
  width: 84px;
  transition: width 0.15s ease-in-out;
}

ul {
  margin: 15px 0;
}

li {
  display: flex;
  flex-direction: column;
  padding: 5px;
}

.H700 {
  z-index: 2;
}

/* For 125% Zoom */
@media only screen and (min-device-width: 1024px) and (min-resolution: 120dpi) {
  .hide_view {
    display: none;
  }

  .settings {
    width: 84px;
  }
}

/* For 150% Zoom */
@media only screen and (min-device-width: 1024px) and (min-resolution: 144dpi) {
  .hide_view {
    display: none;
  }

  .settings {
    width: 84px;
  }
}

/* For 175% Zoom */
@media only screen and (min-device-width: 1024px) and (min-resolution: 168dpi) {
  .hide_view {
    display: none;
  }

  .settings {
    width: 84px;
  }
}
</style>
