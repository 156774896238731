import Cookies from "cookies-ts";
import jwt_decode from "jwt-decode";
import { UserType } from "@/types/authTypes";
const cookies = new Cookies();
const TOKEN = "AUTH_TOKEN";
/**
 * Sets the given token in the browser cookies with an expiration time
 * equal to the difference between the token's expiration time and the
 * current time.
 *
 * @param {string} token The token to set.
 */
export function setToken(token: string) {
  if (token) {
    const time = Number(Date.now());
    const decodeToken: UserType = jwt_decode(token);
    const expires = Number(decodeToken.exp) * 1000;
    const expiringTime = Math.floor((expires - time) / 1000);
    expiringTime > 0 && cookies.set(TOKEN, token, { expires: expiringTime });
  }
}
export async function logout() {
  cookies.set(TOKEN, null, { expires: -60 * 60 * 8 });
}
export function getToken() {
  return cookies.get(TOKEN);
}

export function isLoggedIn() {
  return !!getToken();
}
/**
 * Retrieves the user id from the token stored in the cookie.
 *
 * @returns {string} The user id.
 */
export function getUserid(): string {
  const user: { id: string } = jwt_decode(getToken() as string);
  return user.id;
}

/**
 * Encrypts the user id stored in the token in the cookie.
 * @returns {string} The encrypted user id.
 */
export function encryptUserid(): string {
  const user: { id: string } = jwt_decode(getToken() as string);
  return user.id;
}

/**
 * Checks if the token stored in the cookie is about to expire (less than or equal to 2 minutes).
 * If the token is null, or about to expire, it returns true, else false.
 * @returns {boolean} true if the token has expired or is about to expire, else false.
 */
export function checkCookieExpiration(): boolean {
  if (getToken() == null) return true;
  const decoded: UserType = jwt_decode(getToken() as string);
  const expiringTimeInMilliseconds = decoded.exp * 1000 - Date.now();
  const expiringTimeInMinutes = expiringTimeInMilliseconds / (1000 * 60);
  if (expiringTimeInMinutes <= 2) return true;
  return false;
}
/**
 * Decodes the token stored in the cookie and returns the UserType.
 *
 * @return {UserType} The decoded UserType.
 */
export function decodeToken(): UserType {
  const decoded: UserType = jwt_decode(getToken() as string);
  return decoded;
}
