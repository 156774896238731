<template>
  <GDialog
    v-model="addDialog"
    max-width="70rem"
    persistent
    data-cy="leaveRequestDialog"
  >
    <div class="p-4">
      <div
        class="flex items-center justify-between"
        data-cy="leaveRequestDialogTitle"
      >
        <h3 class="H600 N900 dark:text-">
          {{
            bulkMode
              ? "Create Bulk Leave"
              : editMode
              ? "Edit Leave Request"
              : "Create Leave Request"
          }}
        </h3>
        <CloseCircle
          @click="
            $emit('closeDialog');
            resetForm();
          "
          data-cy="closeLeaveRequestDialog"
        />
      </div>

      <!-- Form Actions -->
      <div class="flex flex-col gap-4 justify-end pt-2">
        <div class="flex flex-col gap-0 md:flex-row md:gap-10">
          <div class="w-full md:w-[30rem]">
            <div class="mt-4">
              <SelectFieldValue
                :showSearch="true"
                :showLabel="true"
                id="leavetypes"
                data-cy="leaveTypes"
                label="Leave type"
                placeholder="Choose a leave type"
                :requireTag="true"
                :options="leavetypes.map((type) => type.title)"
                v-model="selectedLeaveType"
              />
            </div>
            <div>
              <div class="flex flex-row items-center justify-between">
                <h4 class="block mb-2 my-1 pt-0.5 P250 N800 dark:text-gray-300">
                  Dates
                </h4>
                <span
                  class="
                    bg-red-100
                    requiredSpan
                    rounded-lg
                    O400
                    text-xs
                    px-1.5
                    mb-2
                    my-2
                    h-fit
                    py-1
                    dark:bg-red-200 dark:text-red-900
                  "
                >
                  Required
                </span>
              </div>
              <div v-if="loadingDatePicker">
                <CalenderSkeleton />
              </div>
              <div
                class="w-full min-h-[24.5rem] bg-slate-200 rounded-lg"
                v-else
              >
                <DatePicker
                  data-cy="datesInput"
                  :key="datePickerKey"
                  :requireTag="true"
                  v-model="selectedDate"
                  separator=" to "
                  no-input
                  as-single
                  use-range
                  :formatter="formatter"
                  :disable-date="disabledDate"
                />
              </div>
            </div>
            <div class="mt-5">
              <MultiLineInputBox
                data-cy="commentField"
                label="Comment"
                :showlength="true"
                :maxlength="200"
                :requireTag="false"
                placeholder="Write a comment"
                v-model="leave.description"
              />
            </div>
            <div v-if="selectedLeaveType !== 'Annual Leave'">
              <strong
                class="
                  flex
                  items-center
                  gap-2
                  mb-2
                  pt-0.5
                  P250
                  N800
                  dark:text-gray-300
                "
                >Upload file
                <span class="cursor-pointer relative group">
                  <ToolTipIcon />
                  <div
                    class="
                      w-[200px]
                      max-h-32
                      bg-opacity-90 bg-[#101840]
                      text-white text-sm
                      rounded
                      p-2
                      absolute
                      -top-10
                      left-full
                      hidden
                      group-hover:block
                      font-light
                    "
                  >
                    Kindly attach leave excuse form for review.
                  </div>
                </span></strong
              >
              <span class="text-gray-500">For non-annual leave</span>
              <fileUpload
                data-cy="requestFile"
                class="truncate"
                @on-file-change="setFiles"
                type="file"
                :multiple="true"
                :maxFileSize="2097152"
                :clear-input="clearInput"
                v-model="leave.document"
              />
            </div>
          </div>
          <div class="w-full md:w-[33rem]">
            <div class="border border-gray-300 py-2 px-5 mt-6 rounded-lg">
              <h4
                class="
                  flex
                  items-center
                  gap-2
                  mb-2
                  my-1
                  pt-0.5
                  P250
                  N800
                  dark:text-gray-300
                "
              >
                Request Details
                <span class="cursor-pointer relative group">
                  <ToolTipIcon />
                  <div
                    class="
                      w-[200px]
                      max-h-32
                      bg-opacity-90 bg-[#101840]
                      text-white text-sm
                      rounded
                      p-2
                      absolute
                      -top-20
                      z-10
                      left-full
                      hidden
                      group-hover:block
                      font-light
                    "
                  >
                    Leave calculations exclude weekends and holidays, ensuring
                    accurate working days considered.
                  </div>
                </span>
              </h4>
              <div class="overflow-y-auto overflow-x-hidden max-h-[23rem]">
                <div
                  class="flex items-center group relative my-2"
                  v-for="(date, index) in datesInBetween"
                  :key="index"
                >
                  <div
                    class="
                      flex
                      items-center
                      justify-between
                      border border-gray-300
                      rounded-lg
                      w-[26rem]
                      group-hover:shadow-md
                      transition
                      duration-300
                    "
                  >
                    <p class="pl-5">
                      {{ formatDateApi(date) }}
                    </p>
                    <div class="w-[150px]">
                      <SelectFieldValue
                        :showSearch="false"
                        :showLabel="false"
                        :isAbsolute="calculateIsAbsolute"
                        id="durations"
                        data-cy="days"
                        placeholder="Days"
                        :options="selectedDurationLabels"
                        v-model="selectedDurations[index]"
                        :minWidth="'w-[50%]'"
                      />
                    </div>
                  </div>
                  <CloseCircle
                    data-cy="clearDay"
                    @click="removeDatesList(index)"
                    class="
                      absolute
                      right-5
                      top-1/2
                      transform
                      -translate-y-1/2
                      opacity-0
                      group-hover:opacity-100
                      transition
                      duration-300
                    "
                  />
                </div>
              </div>
              <p
                data-cy="daysAvailable"
                class="pt-2"
                v-if="MAX_DAYS_BETWEEN !== undefined && !bulkMode"
              >
                {{ MAX_DAYS_BETWEEN.toFixed(2) }} days available
              </p>
              <p
                data-cy="carryOver"
                v-if="
                  route.path === '/leave-hr-employee-information' &&
                  leaveDaysCarryOver > 0 &&
                  !bulkMode
                "
                class="text-[0.7rem] O300"
              >
                <span class="N700">Has </span
                >{{ leaveDaysCarryOver.toFixed(2) }} carry over
              </p>

              <p
                data-cy="carryOver"
                v-else-if="leaveDaysCarryOver > 0 && !bulkMode"
                class="text-[0.7rem] O300"
              >
                <span class="N700">{{ leaveDaysAccrued.toFixed(2) }}</span> +
                {{ leaveDaysCarryOver.toFixed(2) }} carry over
              </p>

              <div data-cy="remainingDays" v-if="totalDuration > '0.00'">
                Total selected duration: {{ totalDuration }}
                <p v-if="!bulkMode">{{ remainingDays }} remaining</p>
              </div>
              <div
                v-if="
                  MAX_DAYS_BETWEEN !== undefined &&
                  !leaveDaysCanNegate &&
                  parseFloat(remainingDays) < 0 &&
                  !bulkMode
                "
                class="
                  flex
                  items-center
                  justify-center
                  bg-[#fae8e1]
                  border-2 border-solid border-[#fd916a]
                  text-[#DD5928]
                  requiredSpan
                  rounded-lg
                  text-xs
                  px-1.5
                  py-1.5
                  mb-1.5
                  my-2
                "
              >
                <div class="pt-1 SPC-MR-200">
                  <InfoClose color="#fd916a" />
                </div>

                <span
                  v-if="
                    MAX_DAYS_BETWEEN !== undefined &&
                    MAX_DAYS_BETWEEN.toFixed(2) == '0.00'
                  "
                >
                  You do not have any leave days at the moment. Keep working
                </span>
                <span
                  v-else-if="
                    !leaveDaysCanNegate && parseFloat(remainingDays) < 0
                  "
                >
                  Unfortunately, you've exhausted your remaining leave days.
                  Please contact your manager or HR.
                </span>
              </div>
              <div v-if="showExcludedDates">
                <h4 class="block mb-2 my-1 pt-0.5 P250 N800 dark:text-gray-300">
                  Holidays
                </h4>
                <h5 class="text-red-500">
                  {{ excludedDates.join(", ") }}
                </h5>
              </div>
            </div>

            <div
              v-if="bulkMode && selectedDurations.length > 0"
              class="flex flex-row items-center gap-2 w-full pt-4"
            >
              <div class="flex flex-col w-full">
                <Multiselect
                  :showSelectAll="true"
                  data-cy="employees"
                  :options="leaveemployees"
                  v-model="selectedEmployees"
                  :multiple="true"
                  :closeOnSelect="false"
                  :blockKeys="['Delete']"
                  :groupSelect="true"
                  :clearOnSelect="false"
                  :preserveSearch="true"
                  :hideSelected="true"
                  :taggable="true"
                  :param="'name'"
                  :label="'Employees'"
                  :trackBy="'id'"
                  :placeholder="'Select employees'"
                  :require-tag="true"
                />
              </div>
            </div>
          </div>
        </div>

        <div
          class="flex items-center justify-end gap-2"
          data-cy="leaveRequestActions"
        >
          <ButtonComponent
            button-label="Cancel"
            variant="secondary"
            data-cy="cancelAddLeaveRequest"
            @click="
              $emit('closeDialog');
              resetForm();
            "
          >
          </ButtonComponent>
          <ButtonComponent
            @click="handleBookLeave"
            :disabled="disableButton"
            :loading="loading"
            data-cy="submitLeaveRequest"
          >
            {{ `${editMode ? "Update" : bulkMode ? "Book" : "Request"} now` }}
          </ButtonComponent>
        </div>

        <AlertComponent
          :message-block="messageBlock"
          data-cy="leaveRequestAlert"
        />
      </div>
    </div>
  </GDialog>
</template>

<script setup lang="ts">
import {
  ref,
  toRefs,
  computed,
  reactive,
  PropType,
  defineProps,
  getCurrentInstance,
  onMounted,
  watchEffect,
  watch,
  Ref,
} from "vue";
import DatePicker from "vue-tailwind-datepicker";
import { useStore } from "@/store";
import { useRoute } from "vue-router";
import {
  formatDateApi,
  formatDatePicker,
} from "@/helpers/leaveRequestFunctions";
import { formatDate, requestDays } from "@/helpers/book-leaves/leaveRequests";
import {
  LeaveRequest,
  LeavePayload,
  Employee,
} from "@/types/book-leaves/leaveRequests";
import { LeaveHoliday } from "@/types/leave-configuration/leaveHolidays";
import CloseCircle from "@/assets/svg-components/close-circle.vue";
import ToolTipIcon from "@/assets/svg-components/tooltip-icon.vue";
import InfoClose from "@/assets/svg-components/info-close.vue";
import CalenderSkeleton from "@/common/ui-kit/loaderSkeletons/CalenderSkeleton.vue";
import MultiLineInputBox from "@/common/ui-kit/Inputs/TextArea.vue";
import SelectFieldValue from "@/common/ui-kit/Inputs/SelectField.vue";
import fileUpload from "@/common/ui-kit/Inputs/UploadFile.vue";
import ButtonComponent from "@/common/ui-kit/button/ButtonComponent.vue";
import AlertComponent from "@/common/ui-kit/AlertComponent.vue";
import Multiselect from "@/common/ui-kit/Inputs/MultiSelectInput.vue";
import {
  sucessMessages,
  infoMessages,
  timer,
  errorMessages,
} from "@/constants";
import { Api } from "@/config/restApiConfig";
import { DocumentNode } from "graphql";
import { MutationOptions, OperationVariables } from "@apollo/client";
import {
  useQuery,
  useMutation,
  useMutationLoading,
} from "@vue/apollo-composable";
import {
  CREATE_LEAVE_REQUEST,
  CREATE_BULK_LEAVE_REQUEST,
  EDIT_LEAVE_REQUEST,
} from "@/graphql/mutations/book-leaves/setLeaveRequests";
import {
  LIST_LEAVE_REQUEST,
  UPCOMING_LIST_LEAVE_REQUEST,
  GET_ACCRUED_DAYS,
  GET_ALL_LEAVE_TYPES,
} from "@/graphql/queries/book-leaves/getLeaveRequests";
import {
  GET_LIST_OF_EMPLOYEES_BY_ORGANIZATION,
  GET_HR_EMPLOYEES_OVERVIEW,
} from "@/graphql/queries/hr-dashboard/getHrApprovals";

const props = defineProps({
  leaveDaysCanNegate: Boolean,
  leaveDaysAccrued: {
    type: Number,
    default: 0,
  },
  leaveDaysCarryOver: {
    type: Number,
    default: 0,
  },
  listLeavesHolidays: {
    type: Array as PropType<LeaveHoliday[]>,
    default: () => [],
  },
  editLeaveRequest: Boolean,
  handleBulkLeave: Boolean,
  employeeId: String,
  editLeaveRequestId: String,
  editLeaveRequestDescription: String,
  editLeaveRequestDocument: String,
  editLeaveRequestLeaveType: String,
  editLeaveRequestFirstLastDaysStart: String,
  editLeaveRequestFirstLastDaysEnd: String,
  editLeaveRequestManagerId: String,
});

const {
  leaveDaysCanNegate,
  leaveDaysAccrued,
  leaveDaysCarryOver,
  listLeavesHolidays,
  employeeId,
  editLeaveRequestId,
  editLeaveRequestDescription,
  editLeaveRequestDocument,
  editLeaveRequestLeaveType,
  editLeaveRequestFirstLastDaysStart,
  editLeaveRequestFirstLastDaysEnd,
  editLeaveRequestManagerId,
} = toRefs(props);

const store = useStore();
let created_by = store.user.user_id?.toString();
const userId = employeeId?.value ?? "";

let editMode = ref(false);
let bulkMode = ref(false);
const addDialog = ref(false);
const selectedLeaveType = ref("Annual Leave");
const selectedEmployees = ref<Employee[]>([]);
const loading = useMutationLoading();
const selectedDate = ref({
  startDate: "",
  endDate: "",
});

const messageBlock = reactive({ open: false, severity: "", message: "" });
const maxDaysExceeded = ref(false);
let excludedDates = ref<string[]>([]);
const showExcludedDates = ref(false);

let leave: LeaveRequest = reactive<LeaveRequest>({
  id: "",
  created_by: "",
  is_hr_approved: false,
  leave_type_id: "",
  leave_type: "",
  selectedDate: {
    startDate: "",
    endDate: "",
  },
  description: "",
  document: "",
  file: "",
  dates: [],
  days: "",
  duration: 0,
  carry_over_used: 0,
  first_last_days_start: "",
  first_last_days_end: "",
  first_last_days: [],
  days_off: 0,
  status: "",
  comments: "",
  employee_detail: "",
  manager_approved: "",
  manager_id: "",
  is_archived: false,
  can_negate: false,
  touch_point_comments: [],
});

const resetForm = () => {
  selectedDate.value = {
    startDate: "",
    endDate: "",
  };
  leave.description = "";
  leave.document = "";
  leave.id = "";
  leave.leave_type_id = "";
  leave.employee_detail = "";
  selectedEmployees.value = [];
  showExcludedDates.value = false;
  selectedDurations.value = [];
  clearInputDates.value = [];
  clearInput.value = true;
  editMode.value = false;
  bulkMode.value = false;
};
const formatter = ref({
  date: "DD MMM YYYY",
  month: "MMM",
});
const calculateIsAbsolute = computed(() => {
  return totalDuration.value.length >= 5;
});

const currentRoute = useRoute().path;
const isHomeRoute = currentRoute === "/" || currentRoute === "/home";
let disabledDate: (date: Date) => boolean;

if (isHomeRoute) {
  disabledDate = (date: Date) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);

    return date < yesterday;
  };
}
const showMessage = (message: string, severity: string) => {
  messageBlock.open = true;
  messageBlock.severity = severity;
  messageBlock.message = message;
  setTimeout(() => {
    messageBlock.open = false;
  }, timer.timerFive);
};
const disableButton = computed(() => {
  let selectedDateIsBeforeYesterday: boolean;
  if (isHomeRoute) {
    selectedDateIsBeforeYesterday = selectedDate.value.startDate
      ? new Date(selectedDate.value.startDate) <
        new Date(new Date().setDate(new Date().getDate() - 1))
      : false;
  } else {
    selectedDateIsBeforeYesterday = false;
  }

  return (
    selectedLeaveType.value.trim().length < 1 ||
    selectedDate.value.startDate.trim().length < 1 ||
    selectedDurations.value.length < 1 ||
    (!leaveDaysCanNegate && parseFloat(remainingDays.value) < 0) ||
    selectedDateIsBeforeYesterday ||
    (bulkMode.value ? selectedEmployees.value.length < 1 : false)
  );
});

const handleBookLeave = () => {
  if (editMode.value) {
    updateLeaveRequest.mutate();
  } else if (bulkMode.value) {
    createBulkLeaveRequest.mutate();
  } else {
    createSingleLeaveRequest.mutate();
  }
};

// Use Leave Types
const initializeLeaveTypes = (
  allLeaveTypes: { id: string; title: string }[]
) => {
  if (isHomeRoute || bulkMode.value) {
    return allLeaveTypes.filter(
      (item: { title: string }) => item.title === "Annual Leave"
    );
  } else {
    return allLeaveTypes;
  }
};

const { result: leaveTypesResult } = useQuery(GET_ALL_LEAVE_TYPES);
const leavetypes = computed<{ id: string; title: string }[]>(() => {
  if (leaveTypesResult.value?.listLeaveTypes) {
    const allLeaveTypes = leaveTypesResult.value.listLeaveTypes
      .filter(
        (item: { id: string; title: string; is_archived: boolean }) =>
          !item.is_archived
      )
      .map((item: { id: string; title: string }) => ({
        id: item.id,
        title: item.title,
      }));

    return initializeLeaveTypes(allLeaveTypes);
  } else {
    return [];
  }
});

let accruedAvailable: number;
let carryover: number;

watchEffect(() => {
  accruedAvailable = leaveDaysAccrued.value;
  carryover = leaveDaysCarryOver.value;
});

const route = useRoute();
let accrued =
  route.path === "/leave-hr-employee-information"
    ? leaveDaysAccrued
    : computed(() => accruedAvailable + carryover);

const MAX_DAYS_BETWEEN = accrued;
const excludeDates = computed(() => {
  if (Array.isArray(listLeavesHolidays?.value)) {
    return listLeavesHolidays?.value.map(
      (holiday) => holiday?.start_day.split("T")[0]
    );
  } else {
    return [];
  }
});

const calculateRemainingDays = () => {
  const usedDays = parseFloat(totalDuration.value);
  const maxDays = MAX_DAYS_BETWEEN?.value;
  if (maxDays !== undefined) {
    const remaining = maxDays - usedDays;
    maxDaysExceeded.value = remaining <= 0;
    return remaining.toFixed(2);
  } else {
    return "N/A";
  }
};

const remainingDays = computed(() => calculateRemainingDays());

const calculateDatesInBetween = (format: number) => {
  const { startDate, endDate } = selectedDate.value;

  if (!startDate || !endDate) return [];

  const start = new Date(startDate);
  const end = new Date(endDate);
  const dates: string[] = [];
  const excluded: string[] = [];

  const maxDays: number = MAX_DAYS_BETWEEN?.value || 0;
  const allowBookingIntoNegative = leaveDaysCanNegate.value;

  normalizeDateToStartOfDay(start);
  normalizeDateToEndOfDay(end);

  while (start <= end) {
    const dateISOString = processDate(
      start,
      excluded,
      dates,
      maxDays,
      allowBookingIntoNegative
    );
    if (dateISOString) {
      excluded.push(dateISOString);
    }
    incrementDate(start);
  }

  excludedDates.value = excluded;
  showExcludedDates.value = excluded.length > 0;

  return dates;
};

const normalizeDateToStartOfDay = (date: Date) => {
  date.setUTCHours(0, 0, 0, 0);
};

const normalizeDateToEndOfDay = (date: Date) => {
  date.setUTCHours(23, 59, 59, 999);
};

const processDate = (
  date: Date,
  excluded: string[],
  dates: string[],
  maxDays: number,
  allowBookingIntoNegative: boolean
) => {
  const dayOfWeek = date.getUTCDay();
  if (dayOfWeek !== 0 && dayOfWeek !== 6) {
    const dateISOString = date.toISOString().split("T")[0];
    if (!allowBookingIntoNegative && dates.length >= maxDays) {
      return null;
    }
    if (excludeDates.value?.includes(dateISOString)) {
      return dateISOString;
    } else {
      dates.push(dateISOString);
    }
  }
  return null;
};

const incrementDate = (date: Date) => {
  date.setUTCDate(date.getUTCDate() + 1);
};

const datesInBetween = computed(() => calculateDatesInBetween(10));

const datesInBetweenPayload = computed(() => calculateDatesInBetween(24));

const clearInputDates: Ref<string[]> = ref([]);
const selectedDurations: Ref<string[]> = ref([]);

watch(
  datesInBetween,
  (newDates) => {
    selectedDurations.value = new Array(newDates.length).fill("1 | Full Day");
  },
  { deep: true }
);

const selectedDurationLabels = requestDays.map(({ id, name }) => {
  return `${id} | ${name}`;
});

const totalDuration = computed(() => {
  const total = selectedDurations.value.reduce(
    (acc, duration) => acc + parseFloat(duration.split(" | ")[0]),
    0
  );
  return total.toFixed(2);
});

const removeDatesList = async (index: number) => {
  const removedDate = datesInBetween.value.splice(index, 1);

  selectedDurations.value.splice(index, 1);

  const removedDatePayloadIndex = datesInBetweenPayload.value.indexOf(
    formatDate(new Date(removedDate[0]), 24)
  );
  if (removedDatePayloadIndex !== -1) {
    datesInBetweenPayload.value.splice(removedDatePayloadIndex, 1);
  }
};

// Use Employees
const isHrRoute = currentRoute === "/leave-hr-employee-information";
const employeeDataInput = {
  data: {
    organization_id: store?.user?.organization,
  },
};

let leaveemployees = ref<{ id: string; name: string }[]>([]);
if (isHrRoute) {
  const { result: leaveEmployeeResult } = useQuery(
    GET_LIST_OF_EMPLOYEES_BY_ORGANIZATION,
    employeeDataInput
  );
  leaveemployees = computed<{ id: string; name: string }[]>(() => {
    if (leaveEmployeeResult.value?.listLeaveEmployees) {
      return leaveEmployeeResult.value.listLeaveEmployees
        .filter(
          (item: { id: string; full_name: string | null }) =>
            item.full_name !== "" && item.full_name !== null
        )
        .map((item: { id: string; full_name: string }) => ({
          id: item.id,
          name: item.full_name,
        }));
    } else {
      return [];
    }
  });
}

watchEffect(() => {
  if (selectedDurations.value.length < 1) {
    selectedEmployees.value = [];
  }
});

// Upload file
const clearInput = ref(false);
const setFiles = (files: FileList) => {
  if (files.length > 0) {
    const file = files[0];
    uploadFile(file);
  }
};

const uploadFile = async (file: File) => {
  const formData = new FormData();
  formData.append("uploadLeaveFile", file);
  try {
    const response = await Api(`/leave-file-upload`, {
      method: "POST",
      body: formData,
    });
    let message = "";
    let severity = "success";
    if (response.ok) {
      const responseData = await response.json();
      if (responseData.url && responseData.url.length > 0) {
        leave.document = responseData.url[0].url;
        message = sucessMessages.fileAttached;
      } else {
        message = infoMessages.noUrlFound;
        severity = "error";
      }
    } else {
      message = errorMessages.fileAttachmentFailed;
      severity = "error";
    }

    // Display message block
    showMessage(message, severity);
  } catch (error) {
    showMessage(errorMessages.networkError, "error");
  }
};

// Mutations & Queries
const { refetch: refetchGetLeaveAccruedData } = useQuery(GET_ACCRUED_DAYS, {
  userId: userId,
});
const { refetch: refetchTableData } = useQuery(LIST_LEAVE_REQUEST, {
  userId: userId,
});
const { refetch: refetchListLeavesBookedTimeOff } = useQuery(
  UPCOMING_LIST_LEAVE_REQUEST,
  { userId: userId }
);

const variablesOverView = {
  data: {
    organization_id: store?.user?.organization,
  },
};

const { refetch: refetchOverviewCounts } = useQuery(
  GET_HR_EMPLOYEES_OVERVIEW,
  variablesOverView
);

const filterPayload = (payload: LeavePayload) =>
  Object.fromEntries(
    Object.entries(payload).filter(([_, value]) => value !== undefined)
  );

const createLeaveRequest = (
  _isBulk: boolean,
  mutation: DocumentNode,
  resolver: () => MutationOptions<string, OperationVariables>
) => useMutation(mutation, resolver);

const createSingleLeaveRequest = createLeaveRequest(
  false,
  CREATE_LEAVE_REQUEST,
  () => {
    const payload: LeavePayload = {
      user_id: userId,
      created_by: created_by?.toString(),
      leave_type_id:
        leavetypes.value.find((type) => type.title === selectedLeaveType.value)
          ?.id ?? "",
      comments: leave.description,
      duration: parseFloat(totalDuration.value),
      dates: datesInBetweenPayload.value,
      file: leave.document,
      is_hr_approved: userId !== created_by?.toString(),
    };

    const filteredPayload = filterPayload(payload);

    return {
      mutation: CREATE_LEAVE_REQUEST,
      variables: { data: filteredPayload },
    };
  }
);

const createBulkLeaveRequest = createLeaveRequest(
  true,
  CREATE_BULK_LEAVE_REQUEST,
  () => {
    const payload: LeavePayload = {
      users: selectedEmployees.value.map(
        (employee: { id: string }) => employee.id
      ),
      created_by: created_by?.toString(),
      leave_type_id:
        leavetypes.value.find((type) => type.title === selectedLeaveType.value)
          ?.id ?? "",
      comments: leave.description,
      duration: parseFloat(totalDuration.value),
      dates: datesInBetweenPayload.value,
      file: leave.document,
      is_hr_approved: true,
    };

    const filteredPayload = filterPayload(payload);

    return {
      mutation: CREATE_BULK_LEAVE_REQUEST,
      variables: { data: filteredPayload },
    };
  }
);

const { emit } = getCurrentInstance() as {
  emit: (event: string, ...args: []) => void;
};

const handleResponse = (severity: "success" | "error", message: string) => {
  showMessage(message, severity);
  resetForm();
  refetchData();
  setTimeout(() => {
    emit("closeDialog");
  }, timer.timerFive);
};
const handleSuccess = (message: string) => {
  handleResponse("success", message);
};
const handleError = (error: { message: string }) => {
  const message =
    error.message.length <= 100
      ? error.message
      : errorMessages.leaveRequestFailed;
  handleResponse("error", message);
};

createSingleLeaveRequest.onDone(() =>
  handleSuccess(sucessMessages.leaveBooked)
);
createSingleLeaveRequest.onError(handleError);

createBulkLeaveRequest.onDone(() => handleSuccess(sucessMessages.leaveBooked));
createBulkLeaveRequest.onError(handleError);

const refetchData = () => {
  refetchTableData();
  refetchListLeavesBookedTimeOff();
  refetchGetLeaveAccruedData();
  refetchOverviewCounts();
};

let datePickerKey = ref(0);
const loadingDatePicker = ref(true);

const simulateDateLoading = () => {
  setTimeout(() => {
    loadingDatePicker.value = false;
  }, timer.timerOneFive);
};

onMounted(() => {
  simulateDateLoading();
});

watchEffect(() => {
  if (props.handleBulkLeave) {
    bulkMode.value = true;
  } else if (props.editLeaveRequest) {
    editMode.value = true;

    const startDays = editLeaveRequestFirstLastDaysStart?.value;
    const endDays = editLeaveRequestFirstLastDaysEnd?.value;

    if (startDays && endDays) {
      selectedDate.value = {
        startDate: formatDatePicker(new Date(startDays)),
        endDate: formatDatePicker(new Date(endDays)),
      };
    }

    leave.leave_type_id = editLeaveRequestLeaveType?.value ?? "";
    leave.id = editLeaveRequestId?.value ?? "";
    leave.description = editLeaveRequestDescription?.value ?? "";
    leave.document = editLeaveRequestDocument?.value ?? "";

    datePickerKey.value += 1;
  } else {
    resetForm();
  }
});

const updateLeaveRequest = useMutation(EDIT_LEAVE_REQUEST, () => ({
  variables: {
    user_id: userId,
    data: {
      id: leave.id,
      user_id: userId,
      manager_id: editLeaveRequestManagerId?.value,
      created_by: created_by?.toString(),
      leave_type_id:
        leavetypes.value.find((type) => type.title === selectedLeaveType.value)
          ?.id ?? "",
      comments: leave.description ?? null,
      duration: parseFloat(totalDuration.value),
      dates: datesInBetweenPayload.value,
      file: leave.document,
    },
  },
}));

updateLeaveRequest.onDone(() =>
  handleSuccess("Leave request updated successfully")
);
updateLeaveRequest.onError((error) => {
  const message =
    error.message.length <= 100 ? error.message : infoMessages.contactSupport;
  showMessage("error", message);
  resetForm();
  refetchData();
  setTimeout(() => {
    emit("closeDialog");
  }, timer.timerFive);
});
</script>
